<template>
  <div class="campaign-preview">
    <h3 class="text-2xl font-normal">
      {{ $t('campaignPreview') }}
    </h3>
    <div class="phone-view w-full border mt-4 p-3 hidden rounded h-full">
      <div class="title-flex w-full flex">
        <div class="flex-one w-1/6  small-flex">
          <img
            :src="profilToken"
            class="w-12 h-12 rounded-full"
          >
        </div>
        <div class="flex-one w-full mt-1">
          <h3 class="font-bold text-xl">
            {{ entreprise.fullname }}
          </h3>
          <h3 class="text-sm font-light">
            {{ $t('sponsored') }}
          </h3>
        </div>
        <div class="flex-one w-1/5 small-flex text-center pl-10 mt-1">
          <svg-icon
            name="ic-email-mobile"
            class="w-8 h-8"
            original
          />
        </div>
      </div>
      <div class="text-lg flex font-light mt-3 relative  h-full ">
        <p class="w-full text-justify  w-1/3">
          <read-more
            class="read-more-component"
            :more-str="$t('readMore')"
            :text="postDescription"
            :less-str="$t('lessMore')"
            :max-chars="150"
          />
        </p>
      </div>
      <div class="mt-2 selected-images w-full h-full">
        <img
          v-if="imageData.length !== 0"
          :src="imageData[0]"
          class="w-full h-full"
        >
        <h3
          v-if="imageData.length > 1"
          class="font-light text-sm mt-2"
        >
          +<span class="font-bold text-sm">{{ imageData.length - 1 }}</span> images
        </h3>
      </div>
      <div
        v-if="!isEmpty(chosedButton)"
        class="button-section mt-2"
      >
        <button class="rounded border px-2 py-3 w-full text-black uppercase  font-normal text-xl">
          {{ chosedButton.label }}
        </button>
      </div>
    </div>
    <div class="phone-section w-full mt-4 p-3 rounded h-full">
      <div class="content-section w-full h-full">
        <div class="mx-auto mr-24  rounded-lg overflow-hidden">
          <div class="sm:flex  px-6 py-4">
            <div class="img-section sm:flex-shrink-0  ">
              <svg-icon
                name="account"
                class="block h-10 w-10 rounded-full"
                original/>
            </div>
            <div class="mt-4 sm:mt-0 content-p  sm:ml-4 rounded-lg w-2/3">
              {{ postDescription }} <br/>
              <b class="uppercase mt-1">{{ entreprise.fullname }}</b>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import { firebaseRef } from '../../../rema-function/firebaseRef'
import { firebaseDb } from '../../../main'
import firebaseStorage from '../../../rema-function/firebaseStorage'
import profilImage from '../../../assets/images/user-profil.png'
export default {
  name: 'NewSmsCampaignPreview',
  props: {
    postDescription: {
      type: String,
      default: ''
    },
    imageData: {
      type: Array,
      default: function () {
        return []
      }
    },
    chosedButton: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      entreprise: {},
      profilToken: '',
      profil: profilImage
    }
  },
  firebase () {
    return {
      entreprise: firebaseDb.ref(firebaseRef.users).child(firebase.auth().currentUser.uid)
    }
  },
  created () {
    let vm = this
    firebaseStorage.getAvatar(firebase.auth().currentUser.uid).then((url) => {
      if (url) {
        // alert('trouvé')
        vm.profilToken = url
      } else {
        // alert('e')
        vm.profilToken = ''
      }
    }).catch((err) => {
      // alert('ee')
      vm.profilToken = ''
      console.log(err.toString())
      return ''
    })
  },
  methods: {
    isEmpty (obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) { return false }
      }

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .phone-section {
    background: url("../../../assets/images/ic-phone-back.png");
    min-height: 30rem;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  .content-section {
    margin-left: 4.5rem;
    margin-top: 10.5rem;
  }
  .content-text {
    width: 50%;
    margin-left: 1%;
    // background-color: $Rema-Content-Background;
  }
  .content-p {
    word-wrap: break-word;
    white-space: pre-line;
    border-radius: 6px;
    padding: 2%;
    background-color: $Rema-Content-Background;
  }
  .img-section {
    align-items: flex-end;
    display: flex;
  }
 </style>
