<template>
  <div class="campaign-preview">
    <h3 class="text-2xl font-normal">
      {{ $t('campaignPreview') }}
    </h3>
    <div class="phone-view w-full border mt-4 p-3  rounded">
      <div class="title-flex w-full flex">
        <div class="flex-one w-1/6  small-flex">
          <img
            :src="profilToken"
            class="w-12 h-12 rounded-full"
          >
        </div>
        <div class="flex-one w-full mt-1">
          <h3 class="font-bold text-xl">
            {{ entreprise.fullname }}
          </h3>
          <h3 class="text-sm font-light">
            {{ $t('sponsored') }}
          </h3>
        </div>
        <div class="flex-one w-1/5 small-flex text-center pl-10 mt-1">
          <svg-icon
            name="ic-email-mobile"
            class="w-8 h-8"
            original
          />
        </div>
      </div>
      <div class="text-lg flex font-light mt-3 relative">
        <p class="w-full text-justify  w-1/3">
          <read-more
            class="read-more-component"
            :more-str="$t('readMore')"
            :text="postDescription"
            :less-str="$t('lessMore')"
            :max-chars="150"
          />
        </p>
      </div>
      <div class="mt-2 selected-images w-full">
        <img
          v-if="imageData.length !== 0"
          :src="imageData[0]"
          class="w-full h-full"
        >
        <h3
          v-if="imageData.length > 1"
          class="font-light text-sm mt-2"
        >
          +<span class="font-bold text-sm">{{ imageData.length - 1 }}</span> images
        </h3>
      </div>
      <h3
        v-if="fileData.length !== 0"
        class="text-lg font-normal mb-1">{{ attachFileName }}</h3>
      <div
        v-if=" typeof fileData !== undefined && fileData.length !== 0 && fileData.length > 1"
        class="w-full-h-full bg-green">
        <svg-icon
          name="doc-preview"
          class="w-full h-full"
          original
        />
      </div>
      <div
        v-if="imageData.length > 1"
        class="flex justify-center">
        <span
          v-for="(item, key) in imageData.length" :key="item + key"
          :class="{'is-green': key === 0, 'is-normal': key !== 0}"
          class="flex-one ml-1 h-2 w-2  rounded-full"></span>
      </div>
      <div
        v-if="!isEmpty(chosedButton)"
        class="button-section mt-2"
      >
        <button class="rounded border px-2 py-3 w-full text-black uppercase  font-normal text-xl">
          {{ chosedButton.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import { firebaseRef } from '../../../rema-function/firebaseRef'
import { firebaseDb } from '../../../main'
import firebaseStorage from '../../../rema-function/firebaseStorage'
export default {
  name: 'NewCampaignPreview',
  props: {
    postDescription: {
      type: String,
      default: ''
    },
    imageData: {
      type: Array,
      default: function () {
        return []
      }
    },
    fileData: {
      type: Array,
      default: function () {
        return {}
      }
    },
    chosedButton: {
      type: Object,
      default: function () {
        return {}
      }
    },
    attachFileName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      entreprise: {},
      profilToken: ''
    }
  },
  firebase () {
    return {
      entreprise: firebaseDb.ref(firebaseRef.users).child(firebase.auth().currentUser.uid)
    }
  },
  created () {
    let vm = this
    firebaseStorage.getAvatar(firebase.auth().currentUser.uid).then((url) => {
      if (url) {
        // alert('trouvé')
        vm.profilToken = url
      } else {
        // alert('e')
        vm.profilToken = ''
      }
    }).catch((err) => {
      // alert('ee')
      vm.profilToken = ''
      console.log(err.toString())
      return ''
    })
  },
  methods: {
    isEmpty (obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) { return false }
      }

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .is-green {
    background-color: $Green-Rema;
  }
  .is-normal {
    background-color: $Grey-background-Rema;
  }
  .phone-view  {
  }
</style>
