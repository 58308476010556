<template>
  <div
    class="campaign-preview"
    @click="emitCloseBlock"
  >
    <popup-base @clickOnBackground="emitCloseBlock">
      <div
        class="rounded"
        @click="emitCloseBlock"
      >
        <div
          class="sub-content rounded bg-white m-auto p-4"
          @click.prevent.stop
        >
          <new-sms-campaign-preview
            :chosed-button="campaign.postButton"
            :post-description="campaign.textContent"
            :image-data="imageData"
          />
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
import ClickOutside from 'vue-click-outside'
import NewSmsCampaignPreview from './new-sms-campaign-preview'
export default {
  name: 'SmsCampaignPreview',
  components: { NewSmsCampaignPreview, PopupBase },
  directives: {
    ClickOutside
  },
  props: {
    campaign: {
      type: Object,
      default: function () {
        return {}
      }
    },
    imageUrl: {
      type: String,
      default: ''
    }
  },
  computed: {
    imageData: function () {
      let ar = []
      ar.push(this.imageUrl)
      return ar
    }
  },
  methods: {
    emitCloseBlock () {
      this.$emit('emitCloseBlock', false)
    },
    hideOption () {
      alert('hi')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .sub-content {
    width: 60%;
  }
</style>
